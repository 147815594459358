<template>
    <div class="inner-section">
      <card>
        <!-- search section start -->
        <template v-slot:searchHeaderTitle>
          <h4 class="card-title">{{ $t('teaGardenService.area_wise_license_report') }} {{ $t('globalTrans.search') }}</h4>
        </template>
        <template v-slot:searchBody>
          <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
            <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" autocomplete="off">
              <b-row>
                <!-- service -->
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                  <ValidationProvider name="Service Name" vid="service_id" rules="required|min_value:1">
                    <b-form-group
                      slot-scope="{ valid, errors }"
                      label-for="year">
                      <template v-slot:label>
                        {{ $t('teaGardenConfig.service_name') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                            plain
                            v-model="search.service_id"
                            :options="serviceList"
                            id="service_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                        </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <!-- brand_name_en  -->
                <b-col sm="4" v-if="search.service_id === 31">
                  <ValidationProvider name="Brand Name" vid="brand_name" :rules="{ required:false }">
                      <b-form-group
                          slot-scope="{ valid, errors }"
                          label-for="brand_name">
                          <template v-slot:label>
                              {{ $t('teaGardenPanel.brand_name') }}
                          </template>
                          <b-form-input
                              type="text"
                              v-model="search.brand_name"
                              :state="errors[0] ? false : (valid ? true : null)">
                          </b-form-input>
                          <div class="invalid-feedback">
                              {{ errors[0] }}
                          </div>
                      </b-form-group>
                  </ValidationProvider>
                </b-col>
                <!-- division -->
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                  <ValidationProvider name="Division Name" vid="division_id">
                    <b-form-group
                        label-for="division_id"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{ $t('globalTrans.division') }}
                        </template>
                        <b-form-select
                        plain
                        v-model="search.division_id"
                        :options="divisionList"
                        id="division_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                        {{ errors[0] }}
                        </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <!-- district -->
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="District Name" vid="district_id">
                      <b-form-group
                          label-for="district_id"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                          {{ $t('globalTrans.district') }}
                          </template>
                          <b-form-select
                          plain
                          v-model="search.district_id"
                          :options="districtList"
                          id="district_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                          {{ errors[0] }}
                          </div>
                      </b-form-group>
                    </ValidationProvider>
                </b-col>
                <!-- city corporationList -->
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <ValidationProvider name="City Corporation" vid="city_corporation_id">
              <b-form-group
                label-for="city_corporation_id"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{$t('globalTrans.city_corporation')}}
              </template>
              <b-form-select
                plain
                v-model="search.city_corporation_id"
                :options="corporationList"
                id="city_corporation_id"
                :state="errors[0] ? false : (valid ? true : null)"
              >
                <template v-slot:first>
                <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
            </ValidationProvider>
                </b-col>
                <!-- upazila list -->
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                  <ValidationProvider name="Upazila" vid="upazila_id">
                    <b-form-group
                      label-for="upazila_id"
                      slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                      {{$t('globalTrans.upazila')}}
                    </template>
                    <b-form-select
                      plain
                      v-model="search.upazila_id"
                      :options="upazilaList"
                      id="upazila_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                    >
                      <template v-slot:first>
                      <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <!-- start date  -->
                <b-col xs="12" sm="12" md="4">
                  <ValidationProvider name="Start date" vid="from_date" :rules="{required:false}">
                    <b-form-group slot-scope="{ valid, errors }" label-for="from_date">
                      <template v-slot:label>
                        {{ $t('globalTrans.date_from') }}
                      </template>
                      <date-picker
                        id="from_date"
                        v-model="search.from_date"
                        class="form-control"
                        :placeholder="$t('globalTrans.select_date')"
                        :state="errors[0] ? false : (valid ? true : null)"
                        :class="errors[0] ? 'is-invalid' : ''"
                      >
                      </date-picker>
                      <div class="invalid-feedback d-block">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <!-- end date  -->
                <b-col xs="12" sm="12" md="4">
                  <ValidationProvider name="End date" vid="to_date" :rules="{required:false}">
                    <b-form-group slot-scope="{ valid, errors }" label-for="to_date">
                      <template v-slot:label>
                        {{ $t('globalTrans.date_to') }}
                      </template>
                      <date-picker
                        id="to_date"
                        v-model="search.to_date"
                        class="form-control"
                        :placeholder="$t('globalTrans.select_date')"
                        :state="errors[0] ? false : (valid ? true : null)"
                        :class="errors[0] ? 'is-invalid' : ''"
                      >
                      </date-picker>
                      <div class="invalid-feedback d-block">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <!-- status -->
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Status" vid="status" :rules="{required: false}">
                        <b-form-group
                            slot-scope="{ valid, errors }"
                            label-for="application_type">
                            <template v-slot:label>
                                {{ $t('globalTrans.status') }}
                            </template>
                            <b-form-select
                                plain
                                v-model="search.status"
                                id="status"
                                :options="statusList"
                                :state="errors[0] ? false : (valid ? true : null)">
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                  <b-button size="sm" variant="primary" class="mt-20" type="submit">
                    <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </ValidationObserver>
        </template>
        <!-- search section end -->
      </card>
      <b-row>
        <b-col md="12" v-if="showData">
          <body-card>
                <!-- table section start -->
                <template v-slot:headerTitle>
                  <h4 class="card-title">{{ $t('teaGardenService.area_wise_license_report') }} {{ $t('globalTrans.list') }}</h4>
                </template>
                <template v-slot:headerAction>
                <a
                  style="color: white"
                  title="Excel"
                  class="btn-add btn-warning float-right mr-1"
                  :href="`${teaGardenServiceBaseUrl}btb/report/area-wise-license-report/${$i18n.locale}/?${Object.keys(search).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(search[key])}`).join('&')}`"
                  target="_blank"
                >
                  {{ $t('globalTrans.export_excel') }}
                </a>
                  <a href="javascript:" class="btn-add" @click="pdfExport">
                     {{ $t('globalTrans.export_pdf') }}
                  </a>
                </template>
                <template v-slot:body>
                  <b-overlay :show="loadingState">
                    <b-row>
                      <b-col>
                        <list-report-head :base-url="teaGardenServiceBaseUrl" uri="/configuration/report-heading/detail" :org-id="5" :service-id="27">
                          <template v-slot:projectNameSlot>
                            {{ }}
                          </template>
                          {{ $t('teaGardenService.area_wise_license_report') }}
                        </list-report-head>
                      </b-col>
                    </b-row>
                    <template>
                      <div>
                        <div class="text-black mb-4 mt-2">
                          <b-row>
                            <b-col md="5">
                              {{ $t('teaGardenConfig.service_name') }}: <strong>{{ search.service_id ? getServiceName(search.service_id) : $t('globalTrans.all') }}</strong>
                            </b-col>
                            <b-col md="4">
                              {{ $t('globalTrans.division') }}: <strong>{{ search.division_id ? getDivisionName(search.division_id) : $t('globalTrans.all') }}</strong>
                            </b-col>
                            <b-col md="3">
                              {{ $t('globalTrans.district') }}: <strong>{{ search.district_id ? getDistrictName(search.district_id) : $t('globalTrans.all') }}</strong>
                            </b-col>
                          </b-row>
                        </div>
                        <div class="table-responsive">
                          <b-table-simple class="tg mt-3" bordered striped hover small caption-top responsive :emptyText="$t('globalTrans.noDataFound')">
                            <b-thead>
                              <b-tr>
                                <b-th class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                                <b-th class="text-center">{{ $t('teaGardenConfig.company_name') }}</b-th>
                                <b-th class="text-center">{{ $t('globalTrans.district') }}</b-th>
                                <b-th class="text-center">{{ $t('externalUser.license_no') }}</b-th>
                                <b-th class="text-center">{{ $t('teaGardenConfig.mobile_no') }}</b-th>
                                <b-th class="text-center">{{ $t('teaGardenConfig.address') }}</b-th>
                                <b-th class="text-center">{{ $t('externalUser.issue_date') }}</b-th>
                              </b-tr>
                            </b-thead>
                            <b-tbody>
                              <template v-if="datas.length">
                                    <b-tr v-for="(item, index) in datas" :key="index">
                                        <b-td class="text-center">{{$n(index + 1)}}</b-td>
                                        <b-td class="text-center">
                                          <span v-if="search.service_id === 30 || search.service_id === 31 || search.service_id === 32 || search.service_id === 33 || search.service_id === 34">
                                              {{ currentLocale === 'en' ? item.taggable?.company_name_en : item.taggable?.company_name_bn }}
                                          </span>
                                        </b-td>
                                        <b-td class="text-center">
                                          <span v-if="search.service_id === 30 || search.service_id === 31 || search.service_id === 32 || search.service_id === 33 || search.service_id === 34">
                                              {{ getDistrictName(item.taggable?.c_district_id) }}
                                          </span>
                                           <span v-else-if="search.service_id === 1 || search.service_id === 3 || search.service_id === 4">
                                              {{ getDistrictName(item.taggable?.district_id) }}
                                          </span>
                                        </b-td>
                                        <b-td class="text-center">
                                          {{ item.registration_no }}
                                        </b-td>
                                         <b-td class="text-center">
                                          <span v-if="search.service_id === 30 || search.service_id === 31 || search.service_id === 32 || search.service_id === 33 || search.service_id === 34 || search.service_id === 1">
                                          {{ $n(item.taggable?.mobile, {useGrouping: false}) }}
                                          </span>
                                          <span v-else-if="search.service_id === 4">
                                            {{ $n(item.taggable?.applicant_mobile, {useGrouping: false}) }}
                                          </span>
                                        </b-td>
                                        <b-td class="text-center">
                                          <span v-if="search.service_id === 30 || search.service_id === 31 || search.service_id === 32 || search.service_id === 33 || search.service_id === 34">
                                          {{ currentLocale === 'en' ? item.taggable?.c_address_en : item.taggable?.c_address_bn }}
                                          </span>
                                          <span v-else-if="search.service_id === 1 || search.service_id === 3 || search.service_id === 4">
                                            {{ currentLocale === 'en' ? item.taggable?.address_en : item.taggable?.address_bn }}
                                          </span>
                                        </b-td>
                                         <b-td class="text-center">
                                          {{ item.issue_date | dateFormat }}
                                        </b-td>
                                    </b-tr>
                              </template>
                              <template v-else>
                                <b-tr>
                                  <b-td colspan="7" class="text-center">{{ $t('globalTrans.noDataFound') }}</b-td>
                                </b-tr>
                              </template>
                            </b-tbody>
                          </b-table-simple>
                        </div>
                      </div>
                    </template>
                  </b-overlay>
                </template>
                <!-- table section end -->
          </body-card>
        </b-col>
      </b-row>
    </div>
  </template>
  <script>
  import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
  import { areaWiseLicenseReportApi } from '../../api/routes'
  import ModalBaseMasterList from '@/mixins/list'
  import ListReportHead from '@/components/custom/TeaGardenReportHead.vue'
  import excel from 'vue-excel-export'
  import Vue from 'vue'
  import { ValidationObserver } from 'vee-validate'
  Vue.use(excel)
  export default {
    mixins: [ModalBaseMasterList],
    components: {
    ListReportHead,
        ValidationObserver
    },
    data () {
      return {
        teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
        search: {
          service_id: 0,
          status: 6,
          division_id: 0,
          district_id: 0,
          city_corporation_id: 0,
          upazila_id: 0
        },
        datas: [],
        searchHeaderData: {
          garden_name_en: '',
          garden_name_bn: ''
        },
        showData: false,
        districtList: [],
        corporationList: [],
        upazilaList: []
      }
    },
    created () {
    },
    watch: {
      'search.limit': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.loadData()
        }
      },
      'search.division_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.districtList = this.getDistrictList(newVal)
        } else {
          this.districtList = []
        }
      },
      'search.district_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.upazilaList = this.getUpazilaList(newVal)
          this.corporationList = this.getcityCorporationList(newVal)
        } else {
          this.upazilaList = []
          this.corporationList = []
        }
      },
      'search.service_id': function (newVal, oldVal) {
        if (newVal !== 31) {
          this.search.brand_name = ''
        }
      },
    currentLocale: function (newVal) {
      this.districtList = this.districtList.map(item => {
                return Object.assign({}, item, { text: newVal === 'en' ? item.text_en : item.text_bn })
            })
      this.upazilaList = this.upazilaList.map(item => {
                return Object.assign({}, item, { text: newVal === 'en' ? item.text_en : item.text_bn })
            })
      this.corporationList = this.corporationList.map(item => {
                return Object.assign({}, item, { text: newVal === 'en' ? item.text_en : item.text_bn })
            })
        }
    },
    computed: {
        serviceList: function () {
          return this.$store.state.TeaGardenService.commonObj.masterServiceList.filter(item => JSON.parse(item.service_type).includes(1)).map(item => {
            if (item.value === 30 || item.value === 31 || item.value === 32 || item.value === 33 || item.value === 4 || item.value === 3 || item.value === 1 || item.value === 34) {
              return Object.assign({}, item)
            }
          }).filter(item => item !== undefined)
        },
        statusList () {
          return [
            { value: 6, text: this.$i18n.locale === 'bn' ? 'অনুমোদিত' : 'Approved' },
            { value: 7, text: this.$i18n.locale === 'bn' ? 'প্রত্যাখ্যান' : 'Reject' }
          ]
        },
        gardenList () {
          return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.status === 1)
        },
        divisionList () {
          return this.$store.state.CommonService.commonObj.divisionList.filter(item => item.status === 1)
        },
        currentLocale () {
            return this.$i18n.locale
        }
    },
    methods: {
      districtRowSpan (checkData) {
        let total = 0
        checkData.forEach((element, key) => {
          element.garden_fact_info.forEach((element2, key2) => {
            total++
          })
        })
        return total
      },
      async pdfExport () {
        const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 5 }, this.search)
        const service = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === 27)
        if (service !== undefined) {
          if (service.office_type_id) {
            params.office_type_id = service.office_type_id
          }
          if (service.office_id) {
            params.office_id = service.office_id
          }
        }
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, areaWiseLicenseReportApi, params)
        var blob = new Blob([result], {
          type: 'application/pdf'
        })
        var url = window.URL.createObjectURL(blob)
        window.open(url).print()
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      },
      searchData () {
        this.loadData()
      },
      async loadData () {
        this.showData = true
        const params = Object.assign({}, this.search)
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const result = await RestApi.getData(teaGardenServiceBaseUrl, areaWiseLicenseReportApi, params)
        if (result.success) {
          this.datas = result.data
        } else {
          this.datas = []
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      },
      getServiceName (id) {
        const obj = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === id)
        if (obj) {
          return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
        }
      },
      getUpazilaList (id) {
        return this.$store.state.CommonService.commonObj.upazilaList.filter(item => item.status === 1 && item.district_id === id)
      },
      getcityCorporationList (id) {
        return this.$store.state.CommonService.commonObj.cityCorporationList.filter(item => item.status === 1 && item.district_id === id)
      },
      getDivisionName (id) {
          const data = this.$store.state.CommonService.commonObj.divisionList.find(item => item.value === id)
          if (typeof data !== 'undefined') {
            return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
          } else {
            return ''
          }
      },
      getGardenName (id) {
        const obj = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(data => data.value === id)
          if (obj !== undefined) {
            if (this.$i18n.locale === 'bn') {
              return obj.text_bn
            } else {
              return obj.text_en
            }
         }
      },
      getDistrictName (id) {
        const obj = this.$store.state.CommonService.commonObj.districtList.find(data => data.value === id)
          if (obj !== undefined) {
            if (this.$i18n.locale === 'bn') {
              return obj.text_bn
            } else {
              return obj.text_en
            }
         }
      },
      getDistrictList (divisionId) {
        return this.$store.state.CommonService.commonObj.districtList.filter(item => item.division_id === parseInt(divisionId))
      }
    }
  }
  </script>
